import { PageWrapper } from "../../layout";
import {
  AddPatient,
  Signature,
  AddressDetails,
  EndGreetings,
  OrderSummary,
  Questioner,
  Prescription,
} from "./sections";
import { useAppData } from "../../contexts/AppContext";
import { COLLECTIONS, Step } from "../../constants/types";
import Auth from "../Login/Auth";
import { Fragment, useState } from "react";
import { addSubcollectionDoc, upsertDoc } from "../../repositories/firebase";
import { OrderStatus } from "../../constants/enums";
import { useSearchParams } from "react-router-dom";
import { User } from "../../constants/collections";
import BillingAddress from "./sections/BillingAddress";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import moment from "moment";

const sendConfirmationEmail = async (
  toEmail: string,
  toName: string,
  rxNumber: string
) => {
  try {
    const result = await emailjs.send(
      "service_7stbza7",
      "template_dxcgduq",
      {
        to_name: toName,
        rxNumber: rxNumber,
        to_email: toEmail,
      },
      "YFPpe2k8krkO9XWgm"
    );
  } catch (error) {}
};

function Home() {
  const {
    appFlow,
    authenticated,
    order,
    data,
    patientsData: { patients },
    disableContinue,
    user,
  } = useAppData();
  const [submitting, setSubmitting] = useState(false);
  const [searchParams] = useSearchParams();

  const onSave = async (CustomStatus='None') => {
    try {
      if ((appFlow.currentStep === Step.Signature || CustomStatus == 'chargedSuccessfully' ) && order?.id) {
        setSubmitting(true);
        const response = await upsertDoc<any>(
          COLLECTIONS.ORDERS,
          {
            orderDate: moment.utc().toDate(),
            currentStatus: {
              status: OrderStatus.Ordered,
              date: new Date(),
            },
            paymentMethod: {
              cardHolderName:
                data.paymentInfo.firstName + " " + data.paymentInfo.lastName,
              cardNumber: data.paymentInfo.creditCardNumber || "",
              expirationMonth: data.paymentInfo.expiryMonth || "",
              expirationYear: data.paymentInfo.expiryYear || "",
              securityCode: data.paymentInfo.securityCode || "",
              zipCode: data.paymentInfo.zipCode || "",
            },
            flavour: data.flavour,
            shippingOption: data.shippingOption,
            medications: data.medications,
            pregBF: data.pregBF || false,
            rushOrder: data.rushOrder || false,
            metadata: { ...order?.metadata },
            totalAmount: data.totalAmount,
            questions: data.questions,
            internalNotes: data.internalNotes,
            signatureWaived: data?.signatureWaived || false,
            pharmacyCall: data.pharmacyCall || false,
          },
          order?.id
        );

        let user = patients.find((p) => p.id === searchParams.get("uid"));
        await upsertDoc<User>(
          COLLECTIONS.USERS,
          {
            email: data.email,
            internalNotes: data.internalNotes,
            address: {
              city: data.addresses.shippingAddress.city,
              postalCode: data.addresses.shippingAddress.zipCode || "",
              state: data.addresses.shippingAddress.state,
              street: data.addresses.shippingAddress.addressLine1,
              street2: data.addresses.shippingAddress.addressLine2,
            },
            sameAddress: data.addresses.bothSame,
            billingAddress: !data.addresses.bothSame
              ? {
                  city: data.addresses.billingAddress.city,
                  postalCode: data.addresses.billingAddress.zipCode || "",
                  state: data.addresses.billingAddress.state,
                  street: data.addresses.billingAddress.addressLine1,
                  street2: data.addresses.billingAddress.addressLine2,
                }
              : {},
          } as any,
          user?.id
        );
        if (data.email && CustomStatus == 'chargedSuccessfully') {
          sendConfirmationEmail(data.email, data.name, order.rxNumber);
        }

        setSubmitting(false);
        appFlow.goToNextStep();
      } else {
        appFlow.goToNextStep();
      }
    } catch (e) {}
  };

  // const onChargePayment = () => {
  //   alert("payment charged");
  // };

  const onChargePayment = async () => {
    const baseUrl =
      "https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app";

    setSubmitting(true);
    try {
      const response = await axios.post(`${baseUrl}/chargeCreditCard`, {
        data: {
          cardInfo: {
            cardCode: data.paymentInfo.securityCode,
            cardNumber: data.paymentInfo.creditCardNumber,
            expiryDate:
              data.paymentInfo.expiryMonth + "/" + data.paymentInfo.expiryYear,
          },
          address: {
            firstName: data.paymentInfo.firstName,
            lastName: data.paymentInfo.lastName,
            company: "Art of Med",
            address: data.addresses.shippingAddress.addressLine1,
            city: data.addresses.shippingAddress.city,
            state: data.addresses.shippingAddress.state,
            zip: data.addresses.shippingAddress.zipCode,
            country: data.addresses.shippingAddress.state,
          },
          amount: data?.totalAmount,
        },
      });
      if (response.data.success) {
        await onSave('chargedSuccessfully');
        notifications.show({
          title: "Payment request submitted successfully!",
          message: response.data.message,
        });
        // add logs
        await addSubcollectionDoc(
          COLLECTIONS.USERS,
          user?.id,
          "user-logs",
          {
            title: `Payment charged.`,
            description: `Payment has been charged of amount $${data?.totalAmount} by ${data?.name}.`,
          },
          {
            createdAt: true,
          }
        );
        appFlow.goToNextStep();
      } else {
        notifications.show({
          title: "Payment request failed",
          message: response.data.message,
          color: "red",
        });
      }
    } catch (error: any) {
      notifications.show({
        title: "Payment request failed!",
        message: error?.response?.data?.message,
        color: "red",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <PageWrapper
      submitting={submitting}
      onContinue={
        authenticated
          ? appFlow.currentStep === Step.OrderSummary
            ? onChargePayment
            : onSave
          : undefined
      }
      disableContinue={
        disableContinue[appFlow.currentStep] ||
        (appFlow.currentStep === Step.AddPatient && !data?.termsAgree)
      }
      onPrevious={
        appFlow.hasPrevStep() && appFlow.currentStep !== Step.EndGreetings
          ? () => {
              appFlow.goToPreviousStep();
            }
          : undefined
      }
      styles={{
        buttonsContainer:
          appFlow.currentStep === Step.EndGreetings
            ? {
                justifyContent: "center",
              }
            : undefined,
      }}>
      {authenticated ? (
        <Fragment>
          {appFlow.currentStep === Step.AddPatient && <AddPatient />}
          {appFlow.currentStep === Step.Prescription && <Prescription />}
          {appFlow.currentStep === Step.Questioner && <Questioner />}
          {appFlow.currentStep === Step.AddressDetails && <AddressDetails />}
          {appFlow.currentStep === Step.BillingAddress && <BillingAddress />}
          {appFlow.currentStep === Step.OrderSummary && <OrderSummary />}
          {appFlow.currentStep === Step.Signature && <Signature />}
          {appFlow.currentStep === Step.EndGreetings && <EndGreetings />}
        </Fragment>
      ) : (
        <Auth />
      )}
    </PageWrapper>
  );
}

export default Home;
